<template>
  <div class="update">
    <el-form :model="form" :rules="$parent.$parent.addFormRules" ref="updateForm" label-width="58px" @submit.prevent>
      <el-form-item label="品名" prop="product">
        <el-select :size="controlSize"
                   filterable
                   v-model="form.product"
                   placeholder="请选择产品"
                   value-key="id"
                   @change="changeProduct">
          <el-option v-for="item in $parent.$parent.products" :key="item.id" :label="item.pname" :value="item">
            <span style="float: left">{{ item.pname }}</span>
            <span style="float: right; color: #8492a6; font-size: 12px">{{ item.fabricName }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="色号" prop="color">
        <el-select :size="controlSize" filterable v-model="form.color" placeholder="请选择色号" value-key="id">
          <el-option v-for="item in colors" :key="item.id" :label="item.colornumber" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="缸数" prop="number">
        <el-input v-model.number.lazy="form.number"
                  :size="controlSize"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder="缸数"/>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.content"
                  :size="controlSize"
                  placeholder="备注"/>
      </el-form-item>
    </el-form>
    <div style="margin-top: 16px; text-align: center;">
      <el-button type="danger" :size="controlSize" @click="handleSave">确认</el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    layerInfo: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      controlSize: "mini",
      form: {
        product: {},
        color: {},
        number: null,
        content: ""
      }
    }
  },

  computed: {
    colors() {
      if (!this.form.product.id) return [];
      return this.form.product.colorcards;
    }
  },

  mounted() {
    this.form = Object.assign(this.form, _.cloneDeep(this.layerInfo))
  },

  methods: {
    async changeProduct(item) {
      console.log(item)
      this.form.color = {}
      await this.$nextTick()
      this.$refs["updateForm"].clearValidate("color")
    },

    handleSave() {
      this.$refs["updateForm"].validate(async valid => {
        if (valid) {
          this.$emit("handle", this.form);
          this.$emit("close");
        }
      })
    }
  }
}
</script>

<style scoped>

</style>